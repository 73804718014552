import React from 'react';
import { Helmet } from 'react-helmet';
// import Fade from 'react-reveal/Fade';
import Container from '../components/Container';
import Svg404 from '../images/404.svg';
import { useSearch } from '../utils/common';

import '../style/404.scss';

export default () => {
  const title = '哎呀，出错了！\n您访问的页面已搬家……';
  const search = useSearch();
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Page not found</title>
        <html lang={'zh-cn' || 'en'} />
        <meta name="description" content="Page not found" />
      </Helmet>
      <section id="page-404" className="page-404">
        <Container>
          {/* <Fade bottom duration={1000} delay={500} distance="30px"> */}
          <p className="title">{title}</p>
          <div>
            <a href={`/${search}`} className="link-button">
              进入Convertlab首页
            </a>
          </div>
          {/* </Fade> */}
          {/* <Fade bottom duration={1000} delay={1000} distance="30px"> */}
          <img src={Svg404} alt="404" className="main-image" />
          {/* </Fade> */}
        </Container>
      </section>
    </>
  );
};
